import React from "react"
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }

  .text-shadow-blue {
    text-shadow: 0 3px 3px #08174e;
  }

  .text-underline {
    text-decoration: underline;
  }

  .animate {
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`

const StyledImg = styled.img`
  width: 250px;
  display: block;

  @media (max-width:787px) {
    width: 150px;
  }
`

const HeaderText = ({ title, subTitle, withButton, buttonText, linkTo }) => (
  <StyledDiv className='container flex flex-col items-center p-4'>
    <h1 className='font-display font-extrabold sm:text-4xl md:text-4xl lg:text-5xl text-white text-shadow-blue text-center sm:mb-4 sm:leading-tight'>
      {title ? title : null}
    </h1>

    <h2 className='w-2/3 sm:w-full font-display sm:text-xl md:text-xl lg:text-2xl font-bold text-white mb-4 text-shadow text-center'>
      {subTitle ? subTitle : null}
    </h2>

    {withButton
      ?
        <Link to={linkTo ? linkTo : '/'}>
          <button className="bg-white text-xl text-blue-800 hover:text-blue-900 border hover:border-blue-900 font-bold py-4 px-10 shadow-xl">
            {buttonText ? buttonText : 'Button'}
          </button>
        </Link>
      :
        null
    }

    <div className="absolute left-0 bottom-0">
      <a href="https://www.expertise.com/ca/westminster/property-management" style={{display: "inline-block", border: "0"}}>
        <StyledImg
          src="https://res.cloudinary.com/expertise-com/image/upload/f_auto,fl_lossy,q_auto/w_auto/remote_media/awards/ca_westminster_property-management_2021_inverse.svg"
          alt="Property Managers in Westminster"
        />
      </a>
    </div>

    <svg className="md:hidden absolute bottom-0 mb-8 fill-current text-blue-900 text-shadow inline-block h-12 w-12 animate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polygon points="9 16.172 2.929 10.101 1.515 11.515 10 20 10.707 19.293 18.485 11.515 17.071 10.101 11 16.172 11 0 9 0"/></svg>
  </StyledDiv>
)

HeaderText.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  withButton: PropTypes.bool,
  buttonText: PropTypes.string,
  linkTo: PropTypes.string,
}

export default HeaderText